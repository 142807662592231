<script>
import { ValidationObserver } from 'vee-validate'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseTextarea from '/~/components/base/textarea/base-textarea.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useCms } from '/~/composables/cms/use-cms'
import { useHelp } from '/~/composables/help'
import { useProvider } from '/~/composables/provider'
import { useUI } from '/~/composables/ui'
import { useUser } from '/~/composables/user'

export default {
  name: 'drawer-help',
  components: {
    BaseInput,
    BaseButton,
    BaseIcon,
    BaseAsidePage,
    BaseTextarea,
    ValidationObserver,
  },
  setup() {
    const { user } = useUser()
    const { sendHelp } = useHelp()
    const { hideEwalletMenu, isRequiredAsteriskVisible } = useUI()
    const { customerServiceNumber } = useProvider()
    const {
      isDarkThemeForEwallet,
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
    } = useCms()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()

    function onBack() {
      hideEwalletMenu()
    }

    return {
      isDarkThemeForEwallet,
      helpTitle,
      helpDescription,
      helpMessageMaxLength,
      helpButtonTitle,
      helpFooterNote,
      user,
      sendHelp,
      customerServiceNumber,
      onBack,
      isRequiredAsteriskVisible,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: '',
      },
      submitting: false,
    }
  },
  computed: {
    contactPhone() {
      // Value might be there, but be empty string
      return this.customerServiceNumber || '1300 059 257'
    },
  },
  created() {
    this.form.name = this.user.fullName || ''
    this.form.email = this.user.email || ''
  },
  methods: {
    async onSubmitForm() {
      this.submitting = true

      this.sendHelp(this.form)
        .then(() => {
          this.$router.push({ hash: '#profile-help-confirmation' })
          this.form.message = ''
        })
        .catch(({ response }) => {
          this.processBackendErrors(response.data || response)
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>

<template>
  <base-aside-page
    title="Help"
    no-padding
    :style="{
      '--color-text': isDarkThemeForEwallet ? '#FFFFFF' : null,
    }"
    :back="onBack"
  >
    <div class="px-5 pb-5">
      <div
        class="font-bold"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
        }"
      >
        {{ helpTitle ?? 'Send us a message' }}
      </div>
      <div v-if="helpDescription" class="mt-2.5" v-html="helpDescription" />
      <div
        class="mt-2.5 rounded-lg p-5"
        :class="{
          'bg-gray-50': !isDarkThemeForEwallet,
          'bg-gray-700': isDarkThemeForEwallet,
        }"
      >
        <validation-observer v-slot="{ handleSubmit }" slim>
          <form @submit.prevent="handleSubmit(onSubmitForm)">
            <base-input
              v-model="form.name"
              :validation="{
                rules: 'required',
                name: 'Name',
              }"
              :disabled="submitting"
              :error="backendErrors.name"
              label="Name"
              required
              name="name"
              :required-asterisk="isRequiredAsteriskVisible"
            />
            <base-input
              v-model="form.email"
              :validation="{
                rules: 'required|email',
                name: 'Email',
              }"
              :disabled="submitting"
              :error="backendErrors.email"
              label="Email"
              required
              name="email"
              type="email"
              :required-asterisk="isRequiredAsteriskVisible"
            />
            <base-textarea
              v-model="form.message"
              :validation="{
                rules: 'required',
                name: 'Message',
              }"
              :disabled="submitting"
              :error="backendErrors.message"
              :maxlength="helpMessageMaxLength"
              :rows="1"
              :label="
                helpMessageMaxLength
                  ? `Message (max character length ${helpMessageMaxLength})`
                  : 'Message'
              "
              required
              name="message"
              :required-asterisk="isRequiredAsteriskVisible"
            />
            <base-button
              type="submit"
              :disabled="submitting"
              full-width
              class="mt-5"
            >
              {{
                submitting ? 'Sending...' : helpButtonTitle ?? 'Send message'
              }}
            </base-button>
          </form>
        </validation-observer>
      </div>
      <div
        class="mt-[30px] font-bold"
        :class="{
          'text-eonx-neutral-600': !isDarkThemeForEwallet,
        }"
      >
        Call us
      </div>
      <div
        class="mt-2.5 rounded-lg p-5"
        :class="{
          'bg-gray-50 text-eonx-neutral-800': !isDarkThemeForEwallet,
          'bg-gray-700': isDarkThemeForEwallet,
        }"
      >
        <div v-if="helpFooterNote" class="space-y-5" v-html="helpFooterNote" />
        <template v-else>
          <a
            class="flex items-center justify-center text-xl font-bold no-underline"
            :href="`tel:${contactPhone}`"
          >
            <base-icon class="mr-2.5" :size="28" svg="v2/custom/phone" />
            {{ contactPhone }}
          </a>
          <div class="mt-[15px] text-center">
            Please note, calls and emails are managed 9.00am to 5.00pm (AEST),
            Monday to Friday.
          </div>
        </template>
      </div>
    </div>
  </base-aside-page>
</template>
